import React from "react";
import "./index.css";

export default function (props: any) {
  return (
    <div className="group">
      <input
        type={props.type}
        required
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
      <span className="highlight"></span>
      <span className="bar"></span>
      <label>{props.label}</label>
    </div>
  );
}
