import React from "react";
import styled from "styled-components";
import QuestionIdBox from "components/find/question/questionIdBox";

interface IdQuestionIState {
  name: string;
  birth: string;
  findIdMessage: string;
  showHint: boolean;
  handleFindIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFindIdSubmit: (event: React.FormEvent) => void;
  handleFindIdkeypress: (event: React.KeyboardEvent) => void;
  pressBeforeButton: () => void;
}

const TextBar = styled.form`
  display: block;
  margin: 1rem 0;
  text-align: center;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
`;

const Button = styled.div`
  width: 5rem;
  display: inline-block;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  font-weight: bold;
  line-height: 1rem;
  border-radius: 1rem;
  padding: 1rem;
`;
const ErrorMessage = styled.span`
  color: red;
`;

const EmptyErrorMessage = styled.div`
  height: 1rem;
`;

const HintBox = styled.div`
  display: block;
`;
const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`;

const HintText = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  margin: 2rem;
`;

const findId = ({
  name,
  birth,
  findIdMessage,
  showHint,
  handleFindIdChange,
  handleFindIdSubmit,
  handleFindIdkeypress,
  pressBeforeButton,
}: IdQuestionIState) => {
  return (
    <>
      {showHint ? (
        <HintBox>
          <TextBox>
            <HintText>{findIdMessage}</HintText>
          </TextBox>
          <Button onClick={pressBeforeButton}>이전</Button>
        </HintBox>
      ) : (
        <TextBar
          onSubmit={handleFindIdSubmit}
          onKeyPress={handleFindIdkeypress}
          autoComplete="off"
        >
          <QuestionIdBox
            name={name}
            birth={birth}
            handleFindIdChange={handleFindIdChange}
          />

          {findIdMessage !== "" ? (
            <ErrorMessage>{findIdMessage}</ErrorMessage>
          ) : (
            <EmptyErrorMessage />
          )}
          <ButtonBox>
            <Button onClick={pressBeforeButton}>이전</Button>
            <Button onClick={handleFindIdSubmit}>조회</Button>
          </ButtonBox>
        </TextBar>
      )}
    </>
  );
};
export default findId;
