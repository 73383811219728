import React from "react";
import styled, { keyframes } from "styled-components";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import CalenderList from "pages/home/list";

const fadeIn = keyframes`
  from {
    opacity: 50%;
  }
  to {
    opacity: 100%;
  }
  `;

let checkMobile = true;
function checkWidth() {
  if (window.matchMedia("( min-width: 1030px )").matches) {
    checkMobile = false;
  } else if (window.matchMedia("( min-width: 700px )").matches) {
    checkMobile = false;
  } else {
    checkMobile = true;
  }
}
checkWidth();

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  animation: ${fadeIn} 0.3s linear;
`;
const AppBox = styled.div`
  margin: 20px 0;
`;

const Background = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  background-color: black;
`;

const Header = styled.div`
  position: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  font-family: "Gloria Hallelujah", sans-serif, cursive;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px 15px 15px ${checkMobile ? "15px" : "30px"};
  z-index: 2;
`;
const MSHstartText = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 14px;
`;
const MSHText = styled.div`
  font-size: 18px;
  margin-left: 0.5rem;
  &:first-child {
    margin-left: 0rem;
  }
`;
const ContentAligner = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const opacityControl = keyframes`
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
`;

const ContentsContainer = styled.div`
  width: 100%;
  max-width: 300px;
  height: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding: 2rem;
  background-color: black;
  box-shadow: rgba(180, 180, 180, 0.3) 0px 1px 2px 2px,
    rgba(180, 180, 180, 0.15) 1px 1px 2px 1px;
  animation: ${opacityControl} 0.2s ease-in-out;

  border: 1px solid rgba(255, 255, 255, 0.05);
`;

const DateWeather = styled.div`
  width: 100%;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
`;

const Date = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
`;
const Content = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  line-height: 1.7rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const LogoutButton = styled.div`
  cursor: pointer;
`;

const LineDevider = styled.div``;

interface homeIState {
  diaryInfo: Array<diaryIState>;
  selectedDate: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  selectedWeather: string;
  setSelectedWeather: React.Dispatch<React.SetStateAction<string>>;
  selectedContents: string;
  setSelectedContents: React.Dispatch<React.SetStateAction<string>>;
}
interface diaryIState {
  index: number;
  date: string;
  weather: string;
  contents: string;
}

const homePresenter = ({
  diaryInfo,
  selectedDate,
  setSelectedDate,
  selectedWeather,
  setSelectedWeather,
  selectedContents,
  setSelectedContents,
}: homeIState) => {
  let years: Array<Array<Array<diaryIState>>> = [];
  let yearMonths: Array<Array<diaryIState>> = [];
  let months = [];

  const hasYears: Set<string> = new Set();
  const hasYearMonth: Set<string> = new Set();

  for (let index = 0; index < diaryInfo.length; index++) {
    const diaryElement = diaryInfo[index];
    if (!hasYears.has(diaryElement.date.split("-")[0])) {
      hasYears.add(diaryElement.date.split("-")[0]);
      hasYearMonth.add(
        `${diaryElement.date.split("-")[0]}-${diaryElement.date.split("-")[1]}`
      );
      if (months.length >= 1) {
        yearMonths.push(months);
      }
      if (yearMonths.length >= 1) {
        years.push(yearMonths);
      }
      yearMonths = [];
      months = [];
      months.push(diaryElement);
    } else if (
      !hasYearMonth.has(
        `${diaryElement.date.split("-")[0]}-${diaryElement.date.split("-")[1]}`
      )
    ) {
      hasYearMonth.add(
        `${diaryElement.date.split("-")[0]}-${diaryElement.date.split("-")[1]}`
      );
      if (months.length >= 1) {
        yearMonths.push(months);
      }
      months = [];
      months.push(diaryElement);
    } else if (index === diaryInfo.length - 1) {
      months.push(diaryElement);
      yearMonths.push(months);
      years.push(yearMonths);
    } else {
      months.push(diaryElement);
    }
  }

  return (
    <Container>
      <Background />
      <Header
        onClick={() => {
          window.location.href = "/home";
        }}
      >
        <MSHstartText>
          <MSHText>M</MSHText>y<MSHText>S</MSHText>ecret
          <MSHText>H</MSHText>istory
        </MSHstartText>
        <LogoutButton
          onClick={() => {
            window.location.href = "/";
            window.sessionStorage.removeItem("userName");
          }}
        >
          <ExitToAppIcon />
        </LogoutButton>
      </Header>
      <AppBox>
        <CalenderList
          years={years}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setSelectedWeather={setSelectedWeather}
          setSelectedContents={setSelectedContents}
        />
        <ContentAligner>
          {selectedDate && (
            <ContentsContainer>
              <DateWeather>
                <Date>
                  {selectedDate.split("-")[0]}년 {selectedDate.split("-")[1]}월{" "}
                  {selectedDate.split("-")[2]}일
                </Date>

                {selectedWeather}
              </DateWeather>
              <Content>
                {selectedContents
                  .split("/n")
                  .map((line: string, index: number) => {
                    return (
                      <span key={index}>
                        {line}
                        <LineDevider />
                      </span>
                    );
                  })}
              </Content>
            </ContentsContainer>
          )}
        </ContentAligner>
      </AppBox>
    </Container>
  );
};
export default homePresenter;
