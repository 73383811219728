import React from "react";
import styled from "styled-components";
import QuestionPwBox from "components/find/question/questionPwBox";

interface PwQuestionIState {
  id: string;
  name: string;
  findPwMessage: string;
  showHint: boolean;
  handleFindPwChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFindPwSubmit: (event: React.FormEvent) => void;
  handleFindPwkeypress: (event: React.KeyboardEvent) => void;
  pressBeforeButton: () => void;
}

const TextBar = styled.form`
  display: block;
  margin: 1rem 0;
  text-align: center;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
`;

const Button = styled.div`
  width: 5rem;
  display: inline-block;
  text-align: center;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  font-weight: bold;
  line-height: 1rem;
  border-radius: 1rem;
  padding: 1rem;
`;
const ErrorMessage = styled.span`
  color: red;
`;

const HintBox = styled.div`
  display: block;
`;
const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`;

const HintText = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  margin: 2rem;
`;

const findPw = ({
  id,
  name,
  findPwMessage,
  showHint,
  handleFindPwChange,
  handleFindPwSubmit,
  handleFindPwkeypress,
  pressBeforeButton,
}: PwQuestionIState) => {
  return (
    <>
      {showHint ? (
        <HintBox>
          <TextBox>
            <HintText>{findPwMessage}</HintText>
          </TextBox>
          <Button onClick={pressBeforeButton}>이전</Button>
        </HintBox>
      ) : (
        <TextBar
          onSubmit={handleFindPwSubmit}
          onKeyPress={handleFindPwkeypress}
          autoComplete="off"
        >
          <QuestionPwBox
            id={id}
            name={name}
            handleFindPwChange={handleFindPwChange}
          />
          <ErrorMessage>{findPwMessage}</ErrorMessage>
          <ButtonBox>
            <Button onClick={pressBeforeButton}>이전</Button>
            <Button onClick={handleFindPwSubmit}>조회</Button>
          </ButtonBox>
        </TextBar>
      )}
    </>
  );
};
export default findPw;
