import React, { useState } from "react";
import HomePresenter from "pages/home/homePresenter";
import Diary from "asset/data/Diary.json";

const HomeContainer = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedWeather, setSelectedWeather] = useState("");
  const [selectedContents, setSelectedContents] = useState();

  const personalData = Diary.mshstart;
  const userName = window.sessionStorage.getItem("userName");
  let DiaryInfo: Array<diaryIState> = [];
  interface diaryIState {
    index: number;
    date: string;
    weather: string;
    contents: string;
  }
  for (let index = 0; index < personalData.length; index++) {
    if (personalData[index].userName === userName) {
      DiaryInfo = personalData[index].Diary;
      break;
    }
  }
  return (
    <HomePresenter
      diaryInfo={DiaryInfo}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      selectedWeather={selectedWeather}
      setSelectedWeather={setSelectedWeather}
      selectedContents={selectedContents}
      setSelectedContents={setSelectedContents}
    />
  );
};
export default HomeContainer;
