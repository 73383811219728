import React from "react";
import styled, { keyframes } from "styled-components";
import { useMediaQuery } from "react-responsive";

import homeImage from "asset/background/night-sky.jpg";
import AuthForm from "components/auth/authForm";
import FindId from "components/find/findId";
import FindPw from "components/find/findPw";

const BackgroundMovement = keyframes`
  0% {
    background-position:  left center;
  }
  50% {
    background-position:  right center;
  }
  100% {
    background-position:  left center;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 50%;
  }
  to {
    opacity: 100%;
  }
`;
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${homeImage});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  animation: ${BackgroundMovement} 500s linear infinite, ${fadeIn} 0.3s linear;
`;

const TitleBox = styled.div`
  font-size: ${(props: { isMobile: boolean; isTablet: boolean }) =>
    props.isMobile ? "1.9" : props.isTablet ? "2.6" : 3}em;

  text-align: center;
  margin-top: 5rem;
`;
const Title = styled.div`
  font-family: "Nanum Myeongjo", serif;
`;
const EmphasisedTitle = styled.span`
  margin-left: 1.3rem;
  font-size: 1.3em;
  &:first-child {
    margin-left: 0;
  }
`;
const SubTitle = styled.div`
  margin: 2rem 0 3rem 0;
  font-size: 1.1em;
`;

const BlurBox = styled.div`
  width: 310px;
  height: 350px;
  display: flex;
  padding: ${(props: { isMobile: boolean }) =>
    props.isMobile ? "0" : "0 1rem"};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

const InnerBox = styled.div`
  display: block;
  text-align: center;
`;
const FindBox = styled.div``;
const ErrorMessage = styled.span`
  color: red;
`;
const EmptyErrorMessage = styled.div`
  height: 1rem;
`;

const FindText = styled.div`
  display: inline-block;
  padding: 1rem;
  cursor: pointer;
  color: #f5cc00;
`;

interface LoginIState {
  id: string;
  password: string;
  errorMessage: string;
  findId: boolean;
  findPassword: boolean;
  name: string;
  birth: string;
  findPwId: string;
  findMessage: string;
  showHint: boolean;
  setFindId: React.Dispatch<React.SetStateAction<boolean>>;
  setFindPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setBirth: React.Dispatch<React.SetStateAction<string>>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFindIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFindPwChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleFindIdSubmit: (event: React.FormEvent) => void;
  handleFindPwSubmit: (event: React.FormEvent) => void;
  pressBeforeButton: () => void;
}

const HomePresenter = ({
  id,
  password,
  errorMessage,
  findId,
  setFindId,
  findPassword,
  setFindPassword,
  showHint,
  findMessage,
  name,
  birth,
  findPwId,
  onChange,
  handleFindIdChange,
  handleFindPwChange,
  handleSubmit,
  handleFindIdSubmit,
  handleFindPwSubmit,
  pressBeforeButton,
}: LoginIState) => {
  const isTablet = useMediaQuery({ query: "(max-width: 1000px) " });
  const isMobile = useMediaQuery({ query: "(max-width: 700px) " });

  const handlekeypress = (event: React.KeyboardEvent) => {
    if (event.key.toLowerCase() === "enter") {
      handleSubmit(event);
    }
  };
  const handleFindIdkeypress = (event: React.KeyboardEvent) => {
    if (event.key.toLowerCase() === "enter") {
      handleFindIdSubmit(event);
    }
  };
  const handleFindPwkeypress = (event: React.KeyboardEvent) => {
    if (event.key.toLowerCase() === "enter") {
      handleFindPwSubmit(event);
    }
  };
  return (
    <Container>
      <TitleBox isMobile={isMobile} isTablet={isTablet}>
        <Title>
          <EmphasisedTitle>M</EmphasisedTitle>y
          <EmphasisedTitle>S</EmphasisedTitle>
          ecret
          <EmphasisedTitle>H</EmphasisedTitle>
          istory
        </Title>
        <SubTitle>start</SubTitle>
      </TitleBox>
      <BlurBox isMobile={isMobile}>
        {findId && (
          <FindId
            name={name}
            birth={birth}
            findIdMessage={findMessage}
            showHint={showHint}
            pressBeforeButton={pressBeforeButton}
            handleFindIdChange={handleFindIdChange}
            handleFindIdSubmit={handleFindIdSubmit}
            handleFindIdkeypress={handleFindIdkeypress}
          />
        )}
        {findPassword && (
          <FindPw
            id={findPwId}
            name={name}
            findPwMessage={findMessage}
            showHint={showHint}
            pressBeforeButton={pressBeforeButton}
            handleFindPwChange={handleFindPwChange}
            handleFindPwSubmit={handleFindPwSubmit}
            handleFindPwkeypress={handleFindPwkeypress}
          />
        )}
        {!(findId || findPassword) && (
          <InnerBox>
            <form
              onSubmit={handleSubmit}
              onKeyPress={handlekeypress}
              autoComplete="off"
            >
              <AuthForm
                id={id}
                password={password}
                onChange={onChange}
                handleSubmit={handleSubmit}
              />
            </form>
            <FindBox>
              <FindText
                onClick={() => {
                  setFindId(true);
                }}
              >
                아이디찾기
              </FindText>
              <FindText
                onClick={() => {
                  setFindPassword(true);
                }}
              >
                비밀번호찾기
              </FindText>
            </FindBox>
            {errorMessage !== "" ? (
              <ErrorMessage>{errorMessage}</ErrorMessage>
            ) : (
              <EmptyErrorMessage />
            )}
          </InnerBox>
        )}
      </BlurBox>
    </Container>
  );
};
export default HomePresenter;
