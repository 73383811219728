import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import home from "routes/home";
import login from "routes/login";

export default function Page() {
  useEffect(() => {
    window.sessionStorage.getItem("userName") === null &&
      window.location.pathname !== "/" &&
      window.location.replace("/");
  });
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={login} />
        <Route path="/home" exact component={home} />
        {window.sessionStorage.getItem("userName") !== null && (
          <Redirect from="*" to="/home" />
        )}
      </Switch>
    </Router>
  );
}
