import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import leftArrow from "asset/leftArrow.png";
import rightArrow from "asset/rightArrow.png";

const Section = styled.div`
  width: auto;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: ${(props: { isPC: boolean }) =>
    props.isPC ? "center" : ""};
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;

  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  font-family: "Texturina", serif;
`;

const LeftArrow = styled.div`
  width: 10px;
  height: 10px;

  position: absolute;
  top: 68px;
  left: 10px;
  opacity: 0.2;
  background-image: url(${leftArrow});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
`;
const RightArrow = styled.div`
  width: 10px;
  height: 10px;

  position: absolute;
  top: 68px;
  right: 10px;
  opacity: 0.2;
  background-image: url(${rightArrow});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
`;

const YearContainer = styled.div`
  display: flex;
`;

const Year = styled.div`
  width: 80px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  background-color: ${(props: { selected: boolean }) =>
    props.selected ? "white" : "black"};
  color: ${(props) => (props.selected ? "black" : "white")};
  transition: background-color 0.2s linear;

  font-size: 22px;
  font-weight: bold;
  margin-left: 20px;
`;
const YearText = styled.div`
  font-size: 15px;
  margin-bottom: 5px;
`;
const OneYear = styled.div`
  display: flex;
`;
const MonthDay = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
`;

const DayAlign = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Day = styled.div`
  width: 100%;
  height: 100%;
  min-width: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  cursor: pointer;

  font-size: ${(props: { selected: boolean }) =>
    props.selected ? "20px" : "14px"};
  color: ${(props: { selected: boolean }) =>
    props.selected ? "#ff8c1a" : "white"};

  transition: color 0.2s linear;
`;

interface listIState {
  years: Array<Array<Array<diaryIState>>>;
  selectedDate: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  setSelectedWeather: React.Dispatch<React.SetStateAction<string>>;
  setSelectedContents: React.Dispatch<React.SetStateAction<string>>;
}

interface diaryIState {
  index: number;
  date: string;
  weather: string;
  contents: string;
}

const List = ({
  years,
  selectedDate,
  setSelectedDate,
  setSelectedWeather,
  setSelectedContents,
}: listIState) => {
  const isPC = useMediaQuery({ query: "(min-width: 1400px) " });
  return (
    <>
      {!isPC && (
        <>
          <LeftArrow />
          <RightArrow />
        </>
      )}
      <Section isPC={isPC}>
        <SectionContainer>
          {years &&
            years.map((year: Array<Array<diaryIState>>, yearIndex: number) => {
              return (
                <YearContainer key={yearIndex}>
                  <Year
                    selected={
                      selectedDate.split("-")[0] ===
                      year[0][0].date.split("-")[0]
                    }
                  >
                    {20 + year[0][0].date.split("-")[0]}
                    <YearText>year</YearText>
                  </Year>
                  <OneYear>
                    {year.map(
                      (month: Array<diaryIState>, monthIndex: number) => {
                        return (
                          <MonthDay
                            key={String(yearIndex) + String(monthIndex)}
                          >
                            <DayAlign>
                              {month.map((day: diaryIState) => {
                                return (
                                  <Day
                                    key={day.index}
                                    selected={
                                      selectedDate.substr(0, 8) ===
                                      day.date.substr(0, 8)
                                    }
                                    onClick={() => {
                                      setSelectedDate(day.date);
                                      setSelectedWeather(day.weather);
                                      setSelectedContents(day.contents);
                                    }}
                                  >
                                    {parseInt(day.date.split("-")[1])}/ &nbsp;
                                    {parseInt(day.date.split("-")[2])}
                                  </Day>
                                );
                              })}
                            </DayAlign>
                          </MonthDay>
                        );
                      }
                    )}
                  </OneYear>
                </YearContainer>
              );
            })}
        </SectionContainer>
      </Section>
    </>
  );
};
export default List;
