import React from "react";
import styled from "styled-components";
import InputBox from "components/input";

const QuestionBox = styled.div`
  display: block;
  margin: 2rem 0 1rem 0;
`;
const QuestionText = styled.div`
  display: block;
  margin: 0 0 2rem 0;
`;

interface IdQuestionIState {
  id: string;
  name: string;
  handleFindPwChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const QuestionIdBox = ({ id, name, handleFindPwChange }: IdQuestionIState) => {
  return (
    <>
      <QuestionBox>
        <QuestionText>회원 정보를 입력해 주세요</QuestionText>
        <InputBox
          label="ID"
          value={id}
          name="id"
          onChange={handleFindPwChange}
        />
        <InputBox
          label="이름"
          name="name"
          value={name}
          onChange={handleFindPwChange}
        />
      </QuestionBox>
    </>
  );
};
export default QuestionIdBox;
