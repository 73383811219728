import React from "react";
import styled from "styled-components";

import InputBox from "components/input";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const LoginBox = styled.div`
  display: flex;
  align-items: center;
`;

const AuthFormBlock = styled.div`
  display: block;
  margin: 2.5rem 1rem 1rem 1rem;
`;

const LoginButton = styled.div`
  width: 3rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background-color: rgba(255, 255, 255, 0.1);

  font-weight: bold;

  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
`;

interface authFormIState {
  id: string;
  password: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
}

const AuthForm = ({ id, password, onChange, handleSubmit }: authFormIState) => {
  return (
    <>
      <LoginBox>
        <AuthFormBlock>
          <InputBox label="ID" name="id" value={id} onChange={onChange} />
          <InputBox
            label="password"
            name="password"
            value={password}
            onChange={onChange}
            type="password"
          />
        </AuthFormBlock>
        <LoginButton onClick={handleSubmit}>
          <ArrowForwardIosIcon />
        </LoginButton>
      </LoginBox>
    </>
  );
};
export default AuthForm;
