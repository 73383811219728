import React from "react";
import styled from "styled-components";
import InputBox from "components/input";

const QuestionBox = styled.div`
  display: block;
  margin: 2rem 0 1rem 0;
`;
const QuestionText = styled.div`
  display: block;
  margin: 0 0 2rem 0;
`;

interface IdQuestionIState {
  name: string;
  birth: string;
  handleFindIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const QuestionIdBox = ({
  name,
  birth,
  handleFindIdChange,
}: IdQuestionIState) => {
  return (
    <>
      <QuestionBox>
        <QuestionText>회원 정보를 입력해 주세요</QuestionText>
        <InputBox
          label="이름"
          name="name"
          value={name}
          onChange={handleFindIdChange}
        />
        <InputBox
          label="생년월일 ex. 900101"
          value={birth}
          name="birth"
          onChange={handleFindIdChange}
        />
      </QuestionBox>
    </>
  );
};
export default QuestionIdBox;
