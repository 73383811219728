import React, { Component } from "react";
import Router from "components/router";
import GlobalStyles from "components/globalStyles";

class App extends Component {
  render() {
    return (
      <>
        <GlobalStyles />
        <Router />
      </>
    );
  }
}

export default App;
