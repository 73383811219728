import React, { useState, useRef } from "react";
import LoginPr1esenter from "pages/login/loginPresenter";

/** 계정정보, 로그인 정보 map */
const ACCOUNT_ID_LIST = ["formypride"];
const ACCOUNT_NAME_LIST = ["박지연"];
const loginInfo = new Map();
loginInfo.set("formypride", {
  password: "jiyeon_7346",
  userName: "박지연",
  account: "jiyeonPark",
});
const findIdInfo = new Map();
findIdInfo.set("박지연", {
  birth: "881030",
  IdHint: "꽃에는 의미가 있다",
  PwHint: "반지에 비밀을 새기다",
});

const LoginContainer = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setMessage] = useState("");
  const [findMessage, setFindMessage] = useState("");
  const [findId, setFindId] = useState(false);
  const [findPassword, setFindPassword] = useState(false);
  const [showHint, setHint] = useState(false);
  const [name, setName] = useState("");
  const [birth, setBirth] = useState("");
  const [findPwId, setFindPwId] = useState("");

  const InputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    if (name === "id") {
      setId(value);
      setMessage("");
    }
    if (name === "password") {
      setPassword(value);
      setMessage("");
    }
  };
  const handleFindIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value, name },
    } = event;
    if (name === "name") {
      setName(value);
      setFindMessage("");
    } else if (name === "birth") {
      setBirth(value);
      setFindMessage("");
    }
  };
  const handleFindPwChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value, name },
    } = event;
    if (name === "id") {
      setFindPwId(value);
      setFindMessage("");
    } else if (name === "name") {
      setName(value);
      setFindMessage("");
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (id === "") {
      setMessage("아이디를 입력해주세요");
      return;
    } else if (!ACCOUNT_ID_LIST.includes(id.toLowerCase())) {
      setMessage("존재하지 않는 아이디 입니다");
      return;
    } else if (loginInfo.get(id.toLowerCase()).password !== password) {
      setMessage("비밀번호가 틀렸습니다");
      return;
    }
    sessionStorage.setItem("userName", loginInfo.get(id.toLowerCase()).account);
    window.location.href = "/home";
  };

  const handleFindIdSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (name === "") {
      setFindMessage("이름을 입력해주세요");
      return;
    } else if (!ACCOUNT_NAME_LIST.includes(name)) {
      setFindMessage("존재하지 않는 회원입니다");
      return;
    } else if (findIdInfo.get(name).birth !== birth) {
      setFindMessage("생년 월일을 확인해주세요");
      return;
    }
    setFindMessage(findIdInfo.get(name).IdHint);
    setHint(true);
    if (!InputRef.current) {
      return;
    }
    InputRef.current.blur();
  };
  const handleFindPwSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (findPwId === "") {
      setFindMessage("아이디를 입력해주세요");
      return;
    } else if (!ACCOUNT_ID_LIST.includes(findPwId.toLowerCase())) {
      setFindMessage("존재하지 않는 아이디 입니다");
      return;
    } else if (
      (await loginInfo.get(findPwId.toLowerCase()).userName) !== name
    ) {
      setFindMessage("이름을 확인해주세요");
      return;
    }
    setFindMessage(await findIdInfo.get(name).PwHint);
    setHint(true);
    if (!InputRef.current) {
      return;
    }
    InputRef.current.blur();
  };

  const pressBeforeButton = () => {
    setId("");
    setPassword("");
    setName("");
    setBirth("");
    setFindPwId("");
    setFindMessage("");
    setMessage("");
    setFindId(false);
    setFindPassword(false);
    setHint(false);
  };

  return (
    <LoginPr1esenter
      id={id}
      password={password}
      errorMessage={errorMessage}
      findId={findId}
      findPassword={findPassword}
      name={name}
      birth={birth}
      findPwId={findPwId}
      findMessage={findMessage}
      showHint={showHint}
      setFindId={setFindId}
      setFindPassword={setFindPassword}
      setName={setName}
      setBirth={setBirth}
      onChange={onChange}
      handleFindIdChange={handleFindIdChange}
      handleFindPwChange={handleFindPwChange}
      handleSubmit={handleSubmit}
      handleFindIdSubmit={handleFindIdSubmit}
      handleFindPwSubmit={handleFindPwSubmit}
      pressBeforeButton={pressBeforeButton}
    />
  );
};
export default LoginContainer;
