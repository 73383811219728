import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const globalStyles = createGlobalStyle`
    ${reset};
    a{
        text-decoration:none;
        color:inherit;
    }
    *{
        box-sizing:border-box;
    }


    body{
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 15px;
        
        font-weight: 400;
        background-color: black;
            
        color: white;
        padding: 0px;
    }
`;

export default globalStyles;
